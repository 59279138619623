import React, { Component } from 'react'
// import ReactDOM from 'react-dom'
import { withRouter } from 'react-router-dom'
import Api from '../../config/siteinfo'
import Modal from '../../pages/common/modal'
import '../../assets/css/orderview.css'
import '../../assets/css/modal.css'
import '../../pages/common/jgoldplan-1.0.0'

class Order extends Component {

    constructor(prop) {
        super(prop)
        this.state = {
            data: {},
            showModal: false
        }
        this.handleShow = this.handleShow.bind(this)
        this.handleHide = this.handleHide.bind(this)

        this.dmobile = React.createRef()
    }

    handleShow() {
       this.setState({showModal: true});
    }
      
    handleHide() {
       this.setState({showModal: false})
       return this.props.history.push('/pay/pay?b=1')
    }

    componentDidMount() {
        this.orderList() // 页面挂载完毕执行
        // 支付回调通知
        var mchData = {action: 'onIframeReady', displayStyle: 'SHOW_CUSTOM_PAGE'}
        var mchData2 = {action: 'jumpOut', jumpOutUrl: Api.glsiteroot + '/#/order/order'}
        var postData = JSON.stringify(mchData)
        var postData2 = JSON.stringify(mchData2)
        window.addEventListener("load", function() {
            this.parent.postMessage(postData, "https://payapp.weixin.qq.com")
            this.parent.postMessage(postData2, "https://payapp.weixin.qq.com")
            // this.postMessage(postData, Api.glsiteroot);
        }, false)
    }

    // 获取参数
    getParams = () => {
        let params = this.props.location.search
        if (!params) {
            console.log('search为空')
            return
        } 
        let par = {}
        params.split('?')[1].split('&').map((e) => {
            let parArr = e.split('=')
            par[parArr[0]] = parArr[1]
            return par
        })
        return par
    }

    orderList = () => {
        let t = this
        let mobile = window.localStorage.getItem('dmobile')
        let par = this.getParams()
        if (par && par['mobile']) {
            if (!mobile) mobile = par['mobile']
        }

        if (!mobile) {
            t.setState({showModal: true})
            // alert('手机号为空')
            return
        }  
        t.gethisenseList(mobile)     
    }

    checkForm = (mobile) => {
        if (!mobile) {
            alert('手机号为空')
            return false
        }
        let reg = /^1[3-9]\d{9}$/
        if (!mobile.match(reg)) {
            alert('手机号格式不正确')
            return false
        }
    }

    // 手机号提交
    mobileSubmit = () => {
        let mobile = this.refs.dmobile.value
        let r = this.checkForm(mobile)
        if (r === false) {
            return
        }
        this.gethisenseList(mobile)
        
    }

    // 获取订单列表数据
    gethisenseList = (mobile) => {
        let t = this

        let formData = new FormData()
        formData.append('mobile', mobile)
       
        fetch(Api.siteroot + '/api/order/gethisense_list', {
            method: 'post',
            body: formData
        }).then(async res => await res.json())
        .then((json) => {
            if (json.status === 1) {
                window.localStorage.setItem('dmobile', mobile)
                t.setState({
                    data: json.data,
                    showModal: false
                })
            } else {
                alert('数据为空')
                return this.props.history.push('/pay/pay?b=1')
            }
        }).catch((err) => {
            throw err
        })
    }

    orderView = () => {

        const modal = this.state.showModal ? (
            <Modal>
              <div className="modal">
                  <div className="modal-size">
                    <div className="modal-head">
                        <label className="title">填写信息</label>
                        <button className="x-close" onClick={this.handleHide}>X</button>
                    </div>
                    <div className="modal-content">
                        <div className="item-content">
                            <label className="title">手机号：</label>
                            <input type="text" ref="dmobile" />
                        </div>
                        <div className="item-content">
                            <button className="submit" onClick={ this.mobileSubmit }>提交</button>
                        </div>
                    </div>
                 </div>
              </div>
            </Modal>
          ) : null;

        return (
            <> 
               { this.state.showModal === true ? modal : null }
                <div className="pay-header">
                    {/* <a href="/#pay/pay?b=1" className="go-back" >
                        <img alt='' src={[require('../../assets/images/go_back.png')]} />
                    </a> */}
                    <span className="pay-title">订单列表</span>
                </div>
                <div className="pay-content">
                    <div className="pay-goods">
                    {
                        this.state.data.length > 0 ? this.state.data.map((item, index) => {
                           return (
                            <div className="pay-goods-order-items" key={index}>
                                <div className="pay-goods-title">
                                <span className="pay-goods-title-ico"></span>
                                <div className="pay-goods-title-name">青岛海信广场</div>
                                <div className="pay-goods-title-state">{item.status_name}</div>
                                </div>
                                <div className="pay-goods-order-items-content">
                                <div className="pay-goods-order-items-img">
                                    <img alt="" src={item.thumb_img} />
                                </div>  
                                <div className="pay-goods-order-items-name">{item.extro.PLUNAME}</div>
                                <div className="pay-goods-order-items-no">{item.extro.PLUCODE}</div>
                                <div className="pay-goods-order-items-floor">{item.extro.DEPNAME}</div>
                                <div className="pay-goods-order-items-mobile">
                                    <a className="pay-goods-order-items-mobile-img" href={"tel:"+item.mobile}>
                                        <img alt="" src={[require('../../assets/images/icon_phone@3x.png')]} />
                                    </a>  
                                    <span className="pay-goods-order-items-mobile-number">{item.mobile}</span>
                                </div>
                                <div className="pay-goods-order-items-money">￥{item.pay_amount}</div>
                                </div>
                                <div className="pay-number-time">
                                <div className="pay-number">流水号：{item.saleno}</div>
                                <div className="pay-time">支付时间：{item.pay_time}</div>
                                <a className="pay-invoice" href={Api.fpsiteroot + '/WebKaiPiao/index.action?m=getFpUrlByQRCode&lsh='+ item.saleno +'&tqm=' + item.udp4 + '&csdm=1009'}>点击开发票</a>
                                </div>
                            </div>
                            )
                        }) : ''
                    }
                    </div>
                </div>
            </>
        )
    }

    render() {
        return (
            <>
                { this.orderView() }
            </>
        )
    }
}

export default withRouter(Order) // 控制路由跳转