import React, { Component } from 'react'
import { HashRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import Pay from '../../pages/pay/pay'
import Order from '../../pages/order/order'

// 配置路径
function homePath() {
    return (
        <>
            <Router>
                <Switch>
                    <Route exact path='/pay/pay' component={ Pay } />
                    <Route exact path='/order/order' component={ Order } />
                    <Route exact path='/' component={ Pay } />
                    <Redirect exact path='/' to='/' />
                    <Redirect exact path='/order/order' to='/order/order' />
                </Switch>
            </Router>
        </>
    )
}

export default class Index extends Component {

    constructor(prop) {
        super(prop)
        this.state = {}
    }

    renderInfo = () => {
        return homePath()
    }

    render() {
        return (
            <>
                {this.renderInfo()}
            </>
        )
    }

}