import React, { Component } from 'react';
import axios from 'axios';
import Qs from 'qs';
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types';
import Api from '../../config/siteinfo';
import '../../assets/css/payorder.css';
import RadioGroup from './RadioGroup'
import Radio from './Radio'
import './mask.scss'
class Pay extends Component {

    constructor(props) {
        super(props)
        this.state = {
            par: true,
            data: {},
            payType:1,
            showTips:false
        }
        this.goods_id = React.createRef()
        this.mobile = React.createRef()
        this.price = React.createRef()
    }

    // 类型检查
    // static propTypes = {
    //     mobile: function(props, propName, componentName) {
    //         console.log(props,propName, componentName)
    //     },
    //     price: PropTypes.number.isRequired
    // }

    // 页面挂载完毕执行
    componentDidMount() {
        this.payList()
    }

    // 获取参数
    getParams = () => {
        let params = this.props.location.search
        if (!params) {
            console.log('search为空')
            return
        } 
        let par = {}
        params.split('?')[1].split('&').map((e) => {
            let parArr = e.split('=')
            par[parArr[0]] = parArr[1]
            return par
        })
        return par
    }

    // 支付页
    payList = () => {
        let t = this
        let par = this.getParams()
        if (par && par['b']) {
            let p = {}
            p['PluCode'] = window.localStorage.getItem('PluCode')
            p['BRANDCODE'] = window.localStorage.getItem('BRANDCODE')
            par = p
        }

        if (par === undefined){
            alert('找不到品牌编码和商品编码')
            t.setState({par: false})
            return
        } 

        let formData = new FormData()
        formData.append('PluCode', par.PluCode)
        formData.append('BRANDCODE', par.BRANDCODE)
        
        fetch(Api.siteroot + '/api/goods/getInfo', {
            method: 'POST',
            body: formData
        }).then( async res => await res.json())
        .then(function(json) {
            if (json.status === 1) {
                window.localStorage.setItem('PluCode', par.PluCode)
                window.localStorage.setItem('BRANDCODE', par.BRANDCODE)
                return t.setState({
                    data : json.data
                })
            } else {
                alert(json.msg)
                t.setState({par: false})
                // console.log('/api/goods/getInfo 失败')
                return
            }
        }).catch((err) => {
            throw err
        })

    }

    checkForm = (mobile, price) => {
        if (!mobile) {
            alert('手机号为空')
            return false
        }
        let reg = /^1[3-9]\d{9}$/
        if (!mobile.match(reg)) {
            alert('手机号格式不正确')
            return false
        }

        let reg2 = /^[0-9]*$|^(-?\d+)(\.\d+)?$/
        if (!price.match(reg2)) {
            alert('金额必须是数字或者浮点数')
            return false
        }
    }
    isWeiXin=()=> {
        let ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            return true;
        } else {
            return false;
        }
    }
    // 提交下单
    paySubmit = (id) => {
        let t = this
        let goods_id = this.refs.goods_id.value
        let mobile = this.refs.mobile.value
        let price = this.refs.price.value  
        let r = this.checkForm(mobile, price)
        if (r === false) {
            return
        }

        goods_id = goods_id === "0" ? id : goods_id;

        let formData = new FormData()
        formData.append('goods_id', goods_id)
        formData.append('mobile', mobile)
        formData.append('price', price)

        // 记录日志
        t.saveLog({
            goods_id: this.refs.goods_id.value,
            goods_id2: goods_id,
            goods_id3: id,
            mobile: mobile,
            price: price,
            formData: formData,
            remark: "提交下单"
        });
        
        fetch(Api.siteroot + '/api/order/hisense_create', {
            method: 'post',
            body: formData
        }).then(async res => await res.json())
        .then((json)=> {
            console.log(json)
            if (json.status === 1) {
                window.localStorage.setItem('dmobile', mobile)
                console.log(t.state.payType)
                if(t.isWeiXin()&&t.state.payType==2){
                    t.setState({
                        showTips:true
                    })
                }else{
                t.state.payType===1?t.wxpayed(json.order_sn, mobile, price):t.state.payType===2?t.airpayed(json.order_sn, mobile, price):t.yunpayed(json.order_sn, mobile, price)
                }
                
            }
        }).catch((err)=>{
            throw err
        }) 
    }

    // 微信付款
    wxpayed = (merOrderId, mobile, totalAmount) => {
        // let t = this
        let formData = new FormData()
        formData.append('merOrderId', merOrderId)
        formData.append('totalAmount', totalAmount)

        fetch(Api.siteroot + '/api/pay/publicpay', {
            method: 'post',
            body: formData
        }).then(async res => await res.json())
        .then((json)=>{
            if (json.status === 1) {
                window.location.href = json.data
            }
        }).catch((err) => {
            throw err
        })
    }
    // 支付宝付款
    airpayed = (merOrderId, mobile, totalAmount) => {
        // let t = this
        let formData = new FormData()
        formData.append('merOrderId', merOrderId)
        formData.append('totalAmount', totalAmount)

        fetch(Api.siteroot + '/api/pay/pay', {
            method: 'post',
            body: formData
        }).then(async res => await res.json())
        .then((json)=>{
            if (json.status === 1) {
                window.location.href = json.data
            }
        }).catch((err) => {
            throw err
        })
    }
    // 支付宝付款
    yunpayed = (merOrderId, mobile, totalAmount) => {
        // let t = this
        let formData = new FormData()
        formData.append('merOrderId', merOrderId)
        formData.append('totalAmount', totalAmount)

        fetch(Api.siteroot + '/api/pay/yunpay', {
            method: 'post',
            body: formData
        }).then(async res => await res.json())
        .then((json)=>{
            if (json.status === 1) {
                window.location.href = json.data
            }
        }).catch((err) => {
            throw err
        })
    }
    // 记录日志
    saveLog = (arr) => {
        axios.post(Api.siteroot + '/api/order/monitor_log', Qs.stringify(arr))
        .then(function(res) {
            console.log(res)
        }).catch(function(err) {
            console.log(err)
        });

    }

    render() {
        return (
            <>
                { this.payView() }
            </>
        )
    }
    payChange = (e)=>{
        this.setState({
            payType:e
        })
    }
    payView = () => {
        return (
            <>  {this.state.showTips?(
                <div className="weixin-tip" onClick={()=>{this.setState({showTips:false})}}>
                    <img src={require("../../assets/images/zhi.png")} className="img1" alt=""/>
                    <div className="tips">
                        <p>支付宝支付请点击右上角</p>
                        <p>选择用浏览器打开</p>
                    </div>
                </div>
            ):null}
                { this.state.par === false ? ( 
                    <>   
                        <div className="pay-header">
                            <span className="pay-title">在线付款</span>
                        </div>
                        <div className="pay-content">
                            <div className="pay-goods">
                            <div className="pay-goods-items">
                                <div style={{fontSize: '2rem'}}>数据为空</div>
                            </div> 
                        </div>
                        </div>
                    </>
                    ) : (
                        <>
                            <div className="pay-header">
                                <span className="pay-title">在线付款</span>
                            </div>
                            <div className="pay-content">
                                <div className="pay-goods">
                                <div className="pay-goods-items">
                                    <input type="hidden" ref="goods_id" value={this.state.data.id || 0}  />
                                    <div className="pay-goods-title">
                                    <span className="pay-goods-title-ico"></span>
                                    <div className="pay-goods-title-name">青岛海信广场</div>
                                    </div>
                                    <div className="pay-goods-items-content">
                                    <div className="pay-goods-items-img">
                                        <img alt="" src={this.state.data.thumb_img} />
                                    </div>  
                                    <div className="pay-goods-items-name">{this.state.data.goods_name}</div>
                                    <div className="pay-goods-items-no">{this.state.data.sn}</div>
                                    <div className="pay-goods-items-floor">{this.state.data.general_column}</div>
                                    <div className="pay-goods-items-mobile">
                                        <a className="pay-goods-items-mobile-img" href={"tel:"+this.state.data.phone}>
                                            <img alt="" src={[require('../../assets/images/icon_phone@3x.png')]} />
                                        </a>  
                                        <span className="pay-goods-items-mobile-number">{this.state.data.phone}</span>
                                    </div>
                                    </div>
                                </div>
                                <div className="pay-phone">
                                        <div className="pay-phone-title">
                                        <span className="pay-phone-ico"></span>
                                        <div className="pay-phone-name">手机号</div>
                                        </div>
                                        <input type="number" placeholder="输入手机号" ref="mobile" />
                                </div>
                                <RadioGroup onChange={this.payChange.bind(this)} active={this.state.payType}>
                                    <Radio value={1}>使用微信支付</Radio>
                                    <Radio value={2}>使用支付宝支付</Radio>
                                    {/* <Radio value={3}>使用云闪付支付</Radio> */}
                                </RadioGroup>
                                <div className="pay-money">金额</div>
                                <div className="pay-money-input">
                                    <div className="pay-money-input-mo">
                                        <label className="money-flag">￥</label>
                                        <input type="number" ref="price" />
                                    </div>
                                </div>
                                </div>
                                <div className="footer">
                                <div className="pay-sub-name">
                                    <button className="pay-sub" onClick={ () => this.paySubmit(this.state.data.id) } type="submit">付款</button>
                                </div>   
                                <div className="view-order">
                                    <a href="/#order/order">查看订单</a>
                                </div> 
                                </div>  
                            </div>
                        </>
                    )
                }
            </>
        )
    }
}

export default withRouter(Pay)