const siteroot = 'https://hspay.sylwtech.cn';
const glsiteroot = 'https://hspay.sylwtech.cn';
const fpsiteroot= 'http://hspay.sylwtech.cn:888';
const version = '1.0.0';

export default {
    siteroot,
    glsiteroot,
    fpsiteroot,
    version
}